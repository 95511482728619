import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect () {
    super.connect()
    this.legacyFunction()
  }

  legacyFunction () {
    var datatable_params = {
      "language": {
        "url": "/configs/datatables-russian.json"
      },

      "bPaginate": false,
      "iDisplayLength": -1,
      "bLengthChange": false,
      "processing": false,
      "serverSide": false,
      "autoWidth": true,
      "columnDefs": [
        { "type": "ru_date", "targets": 6 }
      ],
      "order": [[ 6, "desc" ]],

    };


    var current_disabled = $('#current-disabled-datatable').dataTable(datatable_params);
    var disabled_history = $('#disabled-history-datatable').dataTable(datatable_params);

  }

}
