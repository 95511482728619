import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect () {
    super.connect()
  }

  click(e){
    var id = this.data.get("id")
    var nested = document.getElementsByClassName("nested-fields-" + id);

    for(var i=0; i< nested.length; i++){
      nested[i].classList.toggle("hidden")
    }

    e.target.classList.toggle("collapsed-group")
  }

}
