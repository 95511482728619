import ApplicationController from './application_controller'

import StimulusReflex from 'stimulus_reflex'
import { debounce } from 'lodash-es'

export default class extends ApplicationController {
  connect () {
    StimulusReflex.register(this)
    this.suggest = debounce(this.suggest, 300)
  }

  suggest (event) {
    this.stimulate(this.data.get("backend"), event.target)
  }

  beforeSearch () {
    // console.log('search')
  }

  afterSearch () {
    // console.log('done')
  }
}
