import ApplicationController from './application_controller'
import Lightpick from 'lightpick'

export default class extends ApplicationController {

  connect () {
    super.connect()
    this.legacyFunction()
  }

  legacyFunction () {
    const pickers = (document.querySelectorAll("[id='datepicker-codes']") || [])
    var left_date  = new Date;
    var right_date = moment().add(6, 'days');

    var calculate_range = function(left, right) {
      var textbox = document.getElementById("output-codes");
      textbox.value = '';
      var generate_code = function(day) {
        var num1 = day.getDate() + (day.getMonth() + 1);
        var num2 = ((day.getDate() * 10000) + (num1 * 100) +
                  (day.getFullYear() % 100)) * 1000;
        var num3 = parseInt(num2 / 997.0);
        if ((num2 % 997) != 0) { num3 += 1; }
        return (((num3 * 997) % 1000) ^ 565);
      }
      while(left <= right){
        textbox.value = textbox.value +
                        moment(left).format('DD.MM.YYYY') +': ' +
                        generate_code(left) + '\n';
        var newDate = left.setDate(left.getDate() + 1);
        left = new Date(newDate);
      }
    };

    pickers.forEach( el => {
      var picker = new Lightpick(
        {
          field: el,
          format: 'DD.MM.YYYY',
          singleDate: false,
          numberOfColumns: 2,
          numberOfMonths: 2,
          onSelect: function(start, end){
            calculate_range(start._d, end._d)
          }
        });

      picker.setDateRange(left_date, right_date);
    })
  }

}
