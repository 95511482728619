import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect () {
    super.connect()
    this.legacyFunction()
  }

  legacyFunction () {
    var datatable_params = {
      "language": {
        "url": "/configs/datatables-russian.json"
      },

      "bPaginate": false,
      "iDisplayLength": -1,
      "bLengthChange": false,
      "processing": false,
      "serverSide": false,
      "autoWidth": true,
    };

    $('#dp-datatable-1').dataTable(datatable_params).rowGrouping(
      { bExpandableGrouping: true,
        iGroupingColumnIndex: 0
      });

    $('#dp-datatable-2').dataTable(datatable_params).rowGrouping(
      { bExpandableGrouping: true,
        iGroupingColumnIndex: 0
      });
  }

}
