import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect () {
    super.connect()
    this.legacyFunction()
  }

  legacyFunction () {
    var filter = ''
    var table = $('#find-meter-datatable').dataTable({
      "processing": true,
      "serverSide": true,
      "iDisplayLength": -1,
      "bLengthChange": false,
      "bPaginate": false,
      "bInfo": false,

      "ajax": $('#find-meter-datatable').data('source'),

      "pagingType": "simple_numbers",
      "pageLength": 20,

      "language": {
        "url": "/configs/datatables-russian.json"
      },

      "columns": [
        {"data": "link_to_property", "searchable": false },
        {"data": "link_to_apartment", "searchable": false },
        {"data": "location", "searchable": false},

        {"data": "device_name", "searchable": false },
        {"data": "link_to_meter"},

        {"defaultContent": "", "data": "module_ident" },

        {"data": "link_to_ticket", "searchable": false}
      ]
    });

    $('#find-meter-search').val('').change();
    $('#find-meter-search').keyup(function(){
      filter = $(this).val()
      table.fnFilter(filter);
    });
  }

}
