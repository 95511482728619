import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect () {
    super.connect()
    this.legacyFunction()
  }

  legacyFunction () {

    var columnsDefault = [
      {"data": "record_date"},
      {"data": "consumption"},
      {"data": "per_day"}
    ];

    var columnsElectric = [
      {"data": "record_date"},
      {"data": "consumption_t1"},
      {"data": "consumption_t2"},
      {"data": "consumption"},
      // {"data": "per_day"}
    ];

    //-------------
    //- BAR CHART -
    //-------------

    function addData(chart, label, data) {
      chart.data.labels.push(label);
      chart.data.datasets.forEach((dataset) => {
          dataset.data.push(data);
      });
      chart.update();
    }

    function removeData(chart) {
      chart.data.labels = [];
      chart.data.datasets.forEach((dataset) => {
          dataset.data = [];
      });
      chart.update();
    }

    // datatables.forEach(function(datatable) {

    var tableSelector = this.data.get("table-selector")
    var chartSelector = this.data.get("chart-selector")

    var columns = columnsDefault;
    var isElectric = false;

    if (tableSelector.includes('electric')) {
      columns = columnsElectric;
      isElectric = true;
    };

    var ctx = document.getElementById(chartSelector).getContext('2d');
    var myChart = new Chart(ctx, {
      type: 'bar',

      data: {
        labels: [],
        datasets: [{
          data: [],
          label: 'Расход',
          backgroundColor: 'rgba(60,141,188,0.9)',
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero:true
            }
          }]
        }
      }
    });

    $("#"+tableSelector).dataTable({
      "processing": true,
      "serverSide": true,

      "searching": false,
      "showNEntries" : false,
      "lengthChange": false,
      "processing": true,

      "ajax": $("#"+tableSelector).data('source'),

      "pagingType": "simple",
      "pageLength": 7,
      "columns": columns,
      "order": [[ 0, "desc" ]],

      "language": {
        "url": "/configs/datatables-russian.json"
      },


      fnFooterCallback: function(nRow, aaData) {
        removeData(myChart);
        var graphField = 'per_day'

        if (isElectric == true) {
          graphField = 'consumption'
        }

        aaData.forEach((row) => {
          addData(myChart, row['record_date'], row[graphField]);
        });
      }

    });
  }

}
