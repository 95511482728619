import ApplicationController from './application_controller'
import Lightpick from 'lightpick'
import Chart from './../packs/vendor/Chart.min'

export default class extends ApplicationController {

  connect () {
    super.connect()
    this.legacyFunction()
  }

  legacyFunction () {
    this.loadDiagChart();
  }

  loadDiagChart() {
    const chartSelector = document.getElementById('property-doughnut-chart');
    new Chart(chartSelector.getContext('2d'), {
      type: 'doughnut',
      data: {
        labels: ["Считано", "Пропустили считывание", "Нет данных"],
        datasets: [
          {
            label: "Диагностика",
            backgroundColor: ["#3cba9f", "#3e95cd","#c45850","#e8c3b9","#8e5ea2"],
            data: [chartSelector.getAttribute('data-total'),
                   chartSelector.getAttribute('data-not-actual'),
                   chartSelector.getAttribute('data-no-data')]
          }
        ]
      },
      options: {
        title: {
          display: false,
          text: 'Диагностика'
        }
      }
    });

    if (document.getElementById('GenericVsp-tab') != null) {
      $("#GenericVsp-tab").trigger("click");
    } else {
      $("#GenericWarmWaterMeter-tab").trigger("click");
    };
  }

}
