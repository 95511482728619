import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect () {
    // super.connect()
  }

  initialize () {
    const mapSelector = this.data.get("selector")
    const lat = this.data.get("lat")
    const long = this.data.get("long")

    if (lat == null || long == null) {
      return null
    }

    if (mapSelector !== null) {
      DG.then(function() {
        var map = DG.map(mapSelector, {
          center: [long, lat],
          zoom: 17
        });

        DG.marker([long, lat]).addTo(map);
      });
    }
  }

}
