import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect () {
    super.connect()
    this.legacyFunction()
  }

  legacyFunction () {

    var datatableConfig = {
      "iDisplayLength": -1,
      "bLengthChange": false,
      "autoWidth": false,
      "processing": true,

      // "ajax": $('#CollectiveReportTotal-datatable').data('source'),
      "pagingType": "simple_numbers",
      "bPaginate": false,

      "language": {
        "url": "/configs/datatables-russian.json"
      },

      // 'rowsGroup': [0],

      // "sDom": "<'row'<'col-sm-12 col-md-6 leftheader h4'l><'col-sm-12 col-md-6'f>>" +
      //         "<'row'<'col-sm-12'tr>>" +
      //         "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",

      "columns": [
        { defaultContent: "", data: "link",  },
        { defaultContent: "", data: "serial_number" },

        // { defaultContent: "", data: "device_role" },
        // { defaultContent: "", data: "notice" },

        { defaultContent: "", data: {
            _:    "record_date_display",
            sort: "record_date_timestamp"
          }
        },

        { defaultContent: "", data: "t1" },
        { defaultContent: "", data: "t2" },

        // { defaultContent: "", data: "w1" },
        // { defaultContent: "", data: "w2" },

        { defaultContent: "", data: "m1" },
        { defaultContent: "", data: "m2" },

        { defaultContent: "", data: "leak" },

        { defaultContent: "", data: "gkal" },
        { defaultContent: "", data: "apartment_area_price" }

      ],

      "order": [[ 4, "desc" ]],

      rowCallback: function(row, data, index){
          // keyIndex = Object.keys(data).indexOf('t2');
          var dateIndex  = 2;
          // var dateRecord = moment(data['record_date_timestamp'], "DD.MM.YYYY");
          var dateRecord = moment.unix(data['record_date_timestamp'])

          var red     = 'rgb(255, 174, 174)'
          var orange  = 'rgb(255, 240, 230)'

          var t1Index = 3;
          var t2Index = t1Index + 1;
          var m1Index = 5;
          var m2Index = m1Index + 1;
          var leakIndex = 7;
          var priceIndex = 9;

          if (moment().diff(dateRecord, 'days') >= 3) {
            $(row).find('td:eq('+dateIndex+')').css('background-color', orange);
          }

          if (data['t2'] != '' && data['t2'] > 55) {
            $(row).find('td:eq('+t2Index+')').css('background-color', red);
          } else if (data['t2'] != '' && data['t2'] > 50) {
            $(row).find('td:eq('+t2Index+')').css('background-color', orange);
          }

          if (data['t1'] != '' && data['m2'] == 0) {
            $(row).find('td:eq('+t1Index+')').css('background-color', red);
          }
          if (data['t2'] != '' && data['m2'] == 0) {
            $(row).find('td:eq('+t2Index+')').css('background-color', red);
          }
          if (data['m1'] != '' && data['m1'] == 0) {
            $(row).find('td:eq('+m1Index+')').css('background-color', red);
          }
          if (data['m2'] != '' && data['m2'] == 0) {
            $(row).find('td:eq('+m2Index+')').css('background-color', red);
          }

          if (data['leak'] != '' && data['leak'] > 50) {
            $(row).find('td:eq('+leakIndex+')').css('background-color', red);
          }

          // подставить в условие медиану
          if (data['apartment_area_price'] != '' && data['apartment_area_price'] > 46) {
            $(row).find('td:eq('+priceIndex+')').css('background-color', red);
          } else if (data['apartment_area_price'] != '' && data['apartment_area_price'] > 43) {
            $(row).find('td:eq('+priceIndex+')').css('background-color', orange);
          }

          $(row).find('td:eq(3)').addClass('group-sep-left');
          $(row).find('td:eq(8)').addClass('group-sep-left');
        }
      }

      // ---------------------------------------

      var datatableConfigMain = Object.assign({}, datatableConfig);
      datatableConfigMain["ajax"] = $('#CollectiveReportTotal-datatable').data('source')

      var datatableConfigSecond = Object.assign({}, datatableConfig);
      datatableConfigSecond["ajax"] = $('#CollectiveReportTotalSecond-datatable').data('source')

      var filter = '';

      // ---------------------------------------

      var mainTable = null;
      mainTable = $('#CollectiveReportTotal-datatable').dataTable(datatableConfigMain);
      mainTable.fnFilter(filter);

      $('#CollectiveReportTotalSearchInput').val('').change();
      $('#CollectiveReportTotalSearchInput').keyup(function(){
        filter = $(this).val()
        mainTable.fnFilter(filter);
      });

      // ---------------------------------------

      var secondTable = null;
      secondTable = $('#CollectiveReportTotalSecond-datatable').dataTable(datatableConfigSecond);
      secondTable.fnFilter(filter);

      $('#CollectiveReportTotalOfficeSearchInput').val('').change();
      $('#CollectiveReportTotalOfficeSearchInput').keyup(function(){
        filter = $(this).val()
        secondTable.fnFilter(filter);
      });

      // ---------------------------------------

  }

}
