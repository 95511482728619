import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect () {
    super.connect()
    this.legacyFunction()
  }

  legacyFunction () {

    //-------------
    //- LINE CHART -
    //--------------
    var ctx1 = document.getElementById("lineChartGkal").getContext('2d');
    var myChart1 = new Chart(ctx1, {
      type: 'bar',
      data: {
        labels: [],
        datasets: [{
          data: [],
          label: 'Гкал',
          backgroundColor: 'rgba(60,141,188,0.9)',
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero:true
            }
          }]
        }
      }
    });


    var ctx2 = document.getElementById("lineChartT").getContext('2d');
    var myChart2 = new Chart(ctx2, {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            label: 'Обратный',
            backgroundColor: 'rgba(210, 214, 222, 1)',
          },
          {
            data: [],
            label: 'Подающий',
            backgroundColor: 'rgba(60,141,188,0.8)',
          }
        ]
      },
    options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero:true
            }
          }]
        }
      }
    });

    function addData(chart, label, data) {
      chart.data.labels.push(label);

      data.forEach((val, index) => {
        chart.data.datasets[index].data.push(val);
      });

      chart.update();
    }

    function removeData(chart) {
      chart.data.labels = [];
      chart.data.datasets.forEach((dataset) => {
        dataset.data = [];
      });
      chart.update();
    }

    // ------------------------------------------

    const initTableDefault = function(selector, ts, footerCallback=null) {
      var tableDefaultParams = {
        // "deferLoading": 0,
        "processing": true,
        "serverSide": true,
        "ajax": $(selector).data('source'),
        "pagingType": "simple_numbers",
        "pageLength": 25,
        "searching": false,
        "lengthChange": false,
        "columns": [
          { orderable: false, defaultContent: "", data: "record_date_link" },
          { orderable: false, className: "group-sep-left", defaultContent: "", data: "TC"+ ts +"_w1" },
          { orderable: false, defaultContent: "", data: "TC"+ ts +"_m1" },
          { orderable: false, defaultContent: "", data: "TC"+ ts +"_t1" },
          { orderable: false, defaultContent: "", data: "TC"+ ts +"_p1" },
          { orderable: false, className: "group-sep-left",  defaultContent: "", data: "TC"+ ts +"_w2" },
          { orderable: false, defaultContent: "", data: "TC"+ ts +"_m2" },
          { orderable: false, defaultContent: "", data: "TC"+ ts +"_t2" },
          { orderable: false, defaultContent: "", data: "TC"+ ts +"_p2" },
          { orderable: false, className: "group-sep-left",  defaultContent: "", data: "TC"+ ts +"_w3" },
          { orderable: false, defaultContent: "", data: "TC"+ ts +"_m3" },
        //  { data: "t3.data" },
        //  { data: "p3.data" },
        //  { data: "w4.data" },
        //  { data: "m4.data" },
          { orderable: false, className: "group-sep-left",  defaultContent: "", data: "TC"+ ts +"_wts" },
          { orderable: false, defaultContent: "", data: "TC"+ ts +"_wgv" },
          { orderable: false, defaultContent: "", data: "TC"+ ts +"_mts" },
          { orderable: false, defaultContent: "", data: "leak" }, //  { data: "leak" },
          // { orderable: false, defaultContent: "", data: "TC"+ ts +"_wsum" },//  { data: "TC1_wsum" },
          { orderable: false, className: "group-sep-left",  defaultContent: "", data: "TC"+ ts +"_n" },
          // { defaultContent: "", data: null, "defaultContent": "--" },//  { data: "t_out" },
        ],

        "language": {
          "url": "/configs/datatables-russian.json"
        },

        rowCallback: function(row, data, index){
          // keyIndex = Object.keys(data).indexOf('t2');
          var dateIndex  = 2;

          var red     = 'rgb(255, 174, 174)'
          var orange  = 'rgb(255, 240, 230)'

          var t1Index = 3;
          var t2Index = 7;

          var m1Index = 2;
          var m2Index = 6;

          var leakIndex = 14;
          var tnarIndex = 15;


          if (data["TC"+ ts +"_t2"] != '' && data["TC"+ ts +"_t2"] > 55) {
            $(row).find('td:eq('+t2Index+')').css('background-color', red);
          } else if (data["TC"+ ts +"_t2"] != '' && data["TC"+ ts +"_t2"] > 50) {
            $(row).find('td:eq('+t2Index+')').css('background-color', orange);
          }

          if (data["TC"+ ts +"_t1"] != '' && data["TC"+ ts +"_m2"] == 0) {
            $(row).find('td:eq('+t1Index+')').css('background-color', red);
          }
          if (data["TC"+ ts +"_t2"] != '' && data["TC"+ ts +"_m2"] == 0) {
            $(row).find('td:eq('+t2Index+')').css('background-color', red);
          }
          if (data["TC"+ ts +"_m1"] != '' && data["TC"+ ts +"_m1"] == 0) {
            $(row).find('td:eq('+m1Index+')').css('background-color', red);
          }
          if (data["TC"+ ts +"_m2"] != '' && data["TC"+ ts +"_m2"] == 0) {
            $(row).find('td:eq('+m2Index+')').css('background-color', red);
          }

          if (data['leak'] != '' && data['leak'] > 20) {
            $(row).find('td:eq('+leakIndex+')').css('background-color', red);
          }

          if (data["TC"+ ts +"_n"] != '' && data["TC"+ ts +"_n"] < 60) {
            $(row).find('td:eq('+tnarIndex+')').css('background-color', orange);
          }

          if (data["TC"+ ts +"_n"] != '' && data["TC"+ ts +"_n"] > 60 && data["TC"+ ts +"_n"] < 1440 ) {
            $(row).find('td:eq('+tnarIndex+')').css('background-color', orange);
          }

          // $(row).find('td:eq(3)').addClass('group-sep-left');
          // $(row).find('td:eq(8)').addClass('group-sep-left');
        },

        fnFooterCallback: footerCallback,
      }

      var newtableParams = Object.assign({}, tableDefaultParams);
      newtableParams['ajax'] = $(selector).data('source');

      return $(selector).dataTable(newtableParams);
    };

    // ------------------------------------------

    const initTable043 = function(selector, ts, footerCallback=null) {
      var tableDefaultParams = {
        // "deferLoading": 0,
        "processing": true,
        "serverSide": true,
        "ajax": $(selector).data('source'),
        "pagingType": "simple_numbers",
        "pageLength": 25,
        "searching": false,
        "lengthChange": false,
        "columns": [
          { orderable: false, defaultContent: "", data: "record_date_link" },
          { orderable: false, className: "group-sep-left", defaultContent: "", data: "TC"+ ts +"_w1" },
          { orderable: false, defaultContent: "", data: "TC"+ ts +"_m1" },
          { orderable: false, defaultContent: "", data: "TC"+ ts +"_v1" },
          { orderable: false, defaultContent: "", data: "TC"+ ts +"_t1" },
          { orderable: false, defaultContent: "", data: "TC"+ ts +"_p1" },

          { orderable: false, className: "group-sep-left",  defaultContent: "", data: "TC"+ ts +"_w2" },
          { orderable: false, defaultContent: "", data: "TC"+ ts +"_m2" },
          { orderable: false, defaultContent: "", data: "TC"+ ts +"_v2" },
          { orderable: false, defaultContent: "", data: "TC"+ ts +"_t2" },
          { orderable: false, defaultContent: "", data: "TC"+ ts +"_p2" },

          { orderable: false, className: "group-sep-left",  defaultContent: "", data: "TC"+ ts +"_w3" },
          { orderable: false, defaultContent: "", data: "TC"+ ts +"_m3" },
          { orderable: false, defaultContent: "", data: "TC"+ ts +"_v3" },

          { orderable: false, className: "group-sep-left", defaultContent: "", data: "leak" }, //  { data: "leak" },
          { orderable: false, className: "group-sep-left", defaultContent: "", data: "TC"+ ts +"_n" },
        ],

        "language": {
          "url": "/configs/datatables-russian.json"
        },

        rowCallback: function(row, data, index){
          // keyIndex = Object.keys(data).indexOf('t2');
          var dateIndex  = 2;

          var red     = 'rgb(255, 174, 174)'
          var orange  = 'rgb(255, 240, 230)'

          var t1Index = 4;
          var t2Index = 9;

          var m1Index = 2;
          var m2Index = 7;

          var leakIndex = 14;
          var tnarIndex = 15;


          if (data["TC"+ ts +"_t2"] != '' && data["TC"+ ts +"_t2"] > 55) {
            $(row).find('td:eq('+t2Index+')').css('background-color', red);
          } else if (data["TC"+ ts +"_t2"] != '' && data["TC"+ ts +"_t2"] > 50) {
            $(row).find('td:eq('+t2Index+')').css('background-color', orange);
          }

          if (data["TC"+ ts +"_t1"] != '' && data["TC"+ ts +"_m2"] == 0) {
            $(row).find('td:eq('+t1Index+')').css('background-color', red);
          }
          if (data["TC"+ ts +"_t2"] != '' && data["TC"+ ts +"_m2"] == 0) {
            $(row).find('td:eq('+t2Index+')').css('background-color', red);
          }
          if (data["TC"+ ts +"_m1"] != '' && data["TC"+ ts +"_m1"] == 0) {
            $(row).find('td:eq('+m1Index+')').css('background-color', red);
          }
          if (data["TC"+ ts +"_m2"] != '' && data["TC"+ ts +"_m2"] == 0) {
            $(row).find('td:eq('+m2Index+')').css('background-color', red);
          }

          if (data['leak'] != '' && data['leak'] > 20) {
            $(row).find('td:eq('+leakIndex+')').css('background-color', red);
          }

          if (data["TC"+ ts +"_n"] != '' && data["TC"+ ts +"_n"] < 60) {
            $(row).find('td:eq('+tnarIndex+')').css('background-color', orange);
          }

          if (data["TC"+ ts +"_n"] != '' && data["TC"+ ts +"_n"] > 60 && data["TC"+ ts +"_n"] < 1440 ) {
            $(row).find('td:eq('+tnarIndex+')').css('background-color', orange);
          }

        },

        fnFooterCallback: footerCallback,
      }

      var newtableParams = Object.assign({}, tableDefaultParams);
      newtableParams['ajax'] = $(selector).data('source');

      return $(selector).dataTable(newtableParams);
    };

    // ------------------------------------------
    var collectiveTs1 = null;
    $('#collective-ts1-tab').on('click', function() {

      const fnFooterCallbackDefault = function(nRow, aaData) {
        removeData(myChart1);
        removeData(myChart2);

        aaData.reverse().forEach((row) => {
          addData(myChart1, row['record_date'], [row['TC1_wts']]);
          addData(myChart2, row['record_date'], [row['TC1_t2'], row['TC1_t1']]);
        });
      };

      const fnFooterCallback043 = function(nRow, aaData) {
        removeData(myChart1);
        removeData(myChart2);

        aaData.reverse().forEach((row) => {
          addData(myChart1, row['record_date'], [row['TC1_w2']]);
          addData(myChart2, row['record_date'], [row['TC1_t2'], row['TC1_t1']]);
        });
      };

      if ($('#collective-ts1-datatable').length > 0) {
        collectiveTs1 = collectiveTs1 ||
          initTableDefault('#collective-ts1-datatable', 1, fnFooterCallbackDefault);
      } else if ($('#collective-ts1-datatable-043').length > 0) {
        collectiveTs1 = collectiveTs1 ||
          initTable043('#collective-ts1-datatable-043', 1, fnFooterCallback043);
      }

    });

    // ------------------------------------------

    var collectiveTs2 = null;
    $('#collective-ts2-tab').on('click', function() {
      collectiveTs2 = collectiveTs2 || initTableDefault('#collective-ts2-datatable', 2);
    });

    // ------------------------------------------

    var collectiveTs3 = null;
    $('#collective-ts3-tab').on('click', function() {
      collectiveTs3 = collectiveTs3 || initTableDefault('#collective-ts3-datatable', 3);
    });

    // ------------------------------------------


    $("#collective-ts1-tab").trigger("click");
  }

}
