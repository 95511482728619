import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect () {
    super.connect()
    this.legacyFunction()
  }

  legacyFunction () {
    this.loadDataTable();
  }

  loadDataTable() {
    var tableDefaultParams = {
      "processing": true,
      "serverSide": false,

      "iDisplayLength": -1,
      "bLengthChange": false,

      "autoWidth": false,
      "bPaginate": false,

      // "ajax": $('#all-properties-datatable').data('source'),
      "pagingType": "full_numbers",

      "select": {
        "style": "multi"
      },

      "columnDefs": [
        { "targets": 0, 'checkboxes': { 'selectRow': true } }
      ],

      "columns": [
        { "data": "id"},
        { "data": "link" },
        { "defaultContent": "Без группы", "data": "group" },
        { "data": "updated_at" },
        { "defaultContent": "", "data": "status_code" },
        { "defaultContent": "", "data": "resources", "type":"string" },
        // { "defaultContent": "", "data": "buttons", "orderable": false },
      ],

      "language": {
        "url": "/configs/datatables-russian.json"
      }
    };

    const initTable = function(selector) {
      var newtableParams = Object.assign({}, tableDefaultParams);
      newtableParams['ajax'] = $(selector).data('source');

      var table = $(selector).dataTable(newtableParams).rowGrouping(
        { bExpandableGrouping: true,
          iGroupingColumnIndex: 2 });

      return table;
    };

    // ------------------------------------------

    var activeTable = null;
    var filter = ''

    var allTable = null;
    $('#all-tab').on('click', function() {
      allTable = allTable ||
        initTable('#all-properties-datatable');

      activeTable = allTable;
      activeTable.fnFilter(filter);
    });
    // ------------------------------------------

    var collectiveTable = null;
    $('#collective-tab').on('click', function() {
      collectiveTable = collectiveTable ||
        initTable('#collective-properties-datatable');

      activeTable = collectiveTable;
      activeTable.fnFilter(filter);
    });
    // ------------------------------------------

    var individualTable = null;
    $('#individual-tab').on('click', function() {
      individualTable = individualTable ||
        initTable('#individual-properties-datatable');

      activeTable = individualTable;
      activeTable.fnFilter(filter);
    });
    // ------------------------------------------

    $('#properties-datatable-search').val('').change();
    $('#properties-datatable-search').keyup(function(){
      filter = $(this).val()
      activeTable.fnFilter(filter);
    });


     // Handle form submission event
     $('#frm-submit').on('submit', function(e){
      var form = this;

      var rows_selected = activeTable.api().column(0).checkboxes.selected();

      $("#frm-submit input[type='hidden']").remove();

      // Iterate over all selected checkboxes
      $.each(rows_selected, function(index, rowId){
         // Create a hidden element
         $(form).append(
             $('<input>')
                .attr('type', 'hidden')
                .attr('name', 'id[]')
                .val(rowId)
         );
      });
    });

    $('#frm-grc-submit').on('submit', function(e){
      var form = this;

      var rows_selected = activeTable.api().column(0).checkboxes.selected();

      $("#frm-grc-submit input[type='hidden']").remove();

      // Iterate over all selected checkboxes
      $.each(rows_selected, function(index, rowId){
         // Create a hidden element
         $(form).append(
             $('<input>')
                .attr('type', 'hidden')
                .attr('name', 'id[]')
                .val(rowId)
         );
      });
    });

    $("#individual-tab").trigger("click");
  }


}
