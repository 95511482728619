import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect () {
    super.connect()
    this.legacyFunction()
  }

  legacyFunction () {

    const tableDefaultParams = {
      "processing": true,
      "serverSide": false,

      "iDisplayLength": -1,
      "bLengthChange": false,

      "autoWidth": false,
      "bPaginate": false,
      "pageLength": 100,

      // "ajax": $('#all-properties-datatable').data('source'),
      "pagingType": "full_numbers",

      "columnDefs": [],

      'select': {
          'style': 'multi'
      },

      "rowsGroup": [6],

      "language": {
        "url": "/configs/datatables-russian.json"
      },

      'rowCallback': function(row, data, index){
        const red     = 'rgb(255, 174, 174)'
        const orange  = 'rgb(255, 240, 230)'

        if (data['priority'] == 'Высокий') {
          $(row).css('background-color', orange);
        }
      }

    };

    const columnsDefault = [
      { "data": "id" },
      { "data": "link_to_ticket" },
      { "data": "property_name" },
      { "data": "apartment" },
      { "data": "resource_span", "type":"string" },
      // { "data": "resource_type" },
      { "data": "reason" },

      { "data": "priority" },
      { "data": "created_at", "type":"ru_date", "order":"desc" },
      // { "data": "stage" },
    ];

    const columnsClosedTickets = [
      { "data": "id" },
      { "data": "link_to_ticket" },
      { "data": "property_name" },
      { "data": "apartment" },
      { "data": "resource_span", "type":"string"  },
      // { "data": "resource_type" },
      { "data": "reason" },
      { "data": "performed_actions" },
      { "data": "created_at", "type":"ru_date" },
      { "data": "completed_at", "type":"ru_date", "order":"desc" },
      { "data": "priority" },
      // { "data": "stage" },
    ];

    const initTable = function(selector, columns, groupingIndex) {
      var newtableParams = Object.assign({}, tableDefaultParams);
      var columnDefs = [
        { "targets": 0, 'checkboxes': { 'selectRow': true } }
      ];
      var order = [];

      newtableParams['ajax'] = $(selector).data('source');
      newtableParams["columns"] = columns;

      for (var i=0; i<columns.length; i++) {
        if (columns[i]["order"] !== undefined) {
          order.push([i, columns[i]["order"]]);
        };

      }
      newtableParams["order"] = order
      newtableParams["columnDefs"] = columnDefs

      var table = $(selector).dataTable(newtableParams)

      if (groupingIndex !== null) {
        table.rowGrouping(
          { bExpandableGrouping: true,
            iGroupingColumnIndex: groupingIndex
          });
      }

      return table;
    };

    var activeTable = null;
    var filter = ''
    // ------------------------------------------

    var openTable = null;
    $('#open-tickets-link').on('click', function() {
      openTable = openTable ||
        initTable('#tickets-open-datatable', columnsDefault, 2);

      activeTable = openTable;
      activeTable.fnFilter(filter);
    });

    // ------------------------------------------

    var closedTable = null;
    $('#closed-tickets-link').on('click', function() {
      closedTable = closedTable ||
        initTable('#tickets-closed-datatable', columnsClosedTickets, null);

      activeTable = closedTable;
      activeTable.fnFilter(filter);
    });

    // ------------------------------------------

    var allTable = null;
    $('#all-tickets-link').on('click', function() {
      allTable = allTable ||
        initTable('#tickets-all-datatable', columnsClosedTickets, null);

      activeTable = allTable;
      activeTable.fnFilter(filter);
    });

    // ------------------------------------------

    $('#tickets-datatable-search').val('').change();
    $('#tickets-datatable-search').keyup(function(){
      filter = $(this).val()
      activeTable.fnFilter(filter);
    });

     // Handle form submission event
    $('#frm-print').on('submit', function(e){
      var form = this;

      var rows_selected = activeTable.api().column(0).checkboxes.selected();

      $("#frm-print input[type='hidden']").remove();

      // Iterate over all selected checkboxes
      $.each(rows_selected, function(index, rowId){
         // Create a hidden element
         $(form).append(
             $('<input>')
                .attr('type', 'hidden')
                .attr('name', 'id[]')
                .val(rowId)
         );
      });
    });


    $("#open-tickets-link").trigger("click");
  }

}
