// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


require("jquery")
require("bootstrap")
require("admin-lte")

import 'bootstrap/dist/css/bootstrap.min';
import 'admin-lte/dist/css/AdminLTE.min';
import 'admin-lte/dist/css/skins/skin-blue.min';

import '@fortawesome/fontawesome-free/scss/fontawesome';
import '@fortawesome/fontawesome-free/scss/solid';

import 'lightpick/css/lightpick';

import 'lightgallery.js/dist/js/lightgallery.min';
import 'lightgallery.js/dist/css/lightgallery.min';
import 'lightgallery.js/dist/css/lg-transitions.min';

import './vendor/dataTables.bootstrap.min'

import moment from 'moment'
import 'moment/locale/ru'
window.moment = moment

require('datatables.net-bs')(window, $);
require('jquery-datatables-checkboxes')(window, $);
import './vendor/jquery.dataTables.grouping';

// FOR RU DATE SORTING BY "TYPE" ATTRIBUTE
import accept_ru_date_sorting from './vendor/jquery.dataTables.date-ru';
accept_ru_date_sorting(jQuery)

import 'controllers';

document.addEventListener("turbolinks:load", function() {
  // turbolinks cached datatables cleanup
  $(".dataTables_info").remove();
  $(".dataTables_filter").remove();
  $(".dataTables_paginate").remove();

  const trees = $('[data-widget="tree"]');
  trees.tree();

  // var controller = document.getElementById("body").getAttribute("data-punchbox-controller");
  // var action = document.getElementById("body").getAttribute("data-punchbox-action");

  $(document).scroll(function() {
    if($(window).scrollTop() > 301) {
      $("#scroll-up-button").removeClass("hide");
    } else {
      $("#scroll-up-button").addClass("hide");
    }
  });

});
