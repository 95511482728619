import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
  connect () {
    StimulusReflex.register(this)
  }

  // beforeReflex (element, reflex) {
  //   console.debug('before called');
  //   if (element.dataset.reflexRoot != undefined) {
  //     $(element.dataset.reflexRoot).fadeTo("fast", 0.25);
  //   }

  //   this.benchmark = performance.now()
  // }

  // reflexError (element, reflex, error) {
  //   alert(`Error invoking a Reflex! ${error}`)
  // }

  // afterReflex (element, reflex) {
  //   console.debug('after called');

  //   if (element.dataset.reflexRoot != undefined) {
  //      $(element.dataset.reflexRoot).fadeTo("fast", 1);
  //   }

  //   console.debug(
  //     reflex,
  //     `${(performance.now() - this.benchmark).toFixed(0)}ms`
  //   )
  // }
}
