import ApplicationController from "./application_controller"
import Lightpick from "lightpick"

export default class extends ApplicationController {
  connect() {
    const picker = this.setComponent()
    if (picker !== null) {
      this.setDate(picker)
    }

    super.connect()
  }

  setComponent() {
    const inputSelector = this.data.get("selector")
    const hiddenSelector = this.data.get("hidden-selector")

    const el = document.getElementById(inputSelector)
    if (el !== null) {
      const picker = new Lightpick({
        field: el,
        format: "DD.MM.YYYY",
        singleDate: false,
        numberOfColumns: 2,
        numberOfMonths: 2,
        onSelect: function(start, end) {

          if (hiddenSelector !== null) {
            const hiddens = document.querySelectorAll(
              "[id='" + hiddenSelector + "']"
            )

            hiddens.forEach( f => {
              f.value = el.value
            })
          }

        }
      })

      return picker
    }

    console.debug("daterange connect: selector not found")
    return null
  }

  setDate(picker) {
    if (this.data.has("days-ago") == true) {
      picker.setDateRange(
        moment().subtract(this.data.get("days-ago"), "days"),
        new Date()
      )
      return true
    }

    if (this.data.has("months-ago") == true) {
      picker.setDateRange(
        moment().subtract(this.data.get("months-ago"), "month"),
        new Date()
      )
      return true
    }

    return false
  }
}
