import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect () {
    const gallerySelector = this.data.get("selector");
    const gallery = document.getElementById(gallerySelector);

    if (gallery !== null) {
      lightGallery(
        gallery,
        { selector: '.lightgallery-item',
          thumbnail: true }
      )
    }

    super.connect()
  }

}
