import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect () {
    super.connect()
    this.legacyFunction()
  }

  legacyFunction () {

    var highlight = function(newText, oldText) {
      var text = '';

      newText.split('').forEach(function(val, i){
        if (val != oldText.charAt(i))
          text += "<span class='highlight'>"+val+"</span>";
        else
          text += val;
      });

      return text;
    };

    var serial_number_is_valid = function(number) {
      var digits_arr = number.toString(10).split("")

      if (digits_arr.length == 9) {
        var checksum = digits_arr.pop();
        var even_sum = 0
        var odd_sum = 0

        for (var index = 0; index < digits_arr.length; ++index) {
          if (index % 2 == 0) {
            even_sum = even_sum + Number(digits_arr[index])
          } else {
            odd_sum = odd_sum + Number(digits_arr[index])
          }
        }

        var y = even_sum + (odd_sum * 3)
        var result = (Math.ceil(y / 10.0) * 10) - y

        return (result == checksum);
      } else {
        return false;
      }
    };

    var near_correct = function(number) {
      var digits_arr = number.toString(10).split("")
      var digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
      var corrects = []

      for (var index = 0; index < digits_arr.length; ++index) {
        for (var digindex = 0; digindex < digits.length; ++digindex) {
          var newArray = Object.assign([], digits_arr, {[index]: digits[digindex]});
          var newNumber = newArray.join('')
          if (serial_number_is_valid(newNumber)) {
            corrects.push(newNumber)
          }

        }
      }

      return corrects;
    };

    $('#input-code').keyup(function(){
      var input = $(this).val();

      var textbox = document.getElementById("output-codes");
      textbox.innerHTML = "";

      if (input.length == 9) {
        var valid = serial_number_is_valid(input)
        if (valid) {
          $('#input-code-result').html("Верно")
        } else {
          $('#input-code-result').html("Ошибка")
          var corrects = near_correct(input);

          corrects.forEach(element => {
            textbox.innerHTML += "<p>" + highlight(element, input) + "</p>"
          });
        }
      } else {
        $('#input-code-result').html("В номере должно быть 9 цифр")
      }
    });

  }

}
