import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect () {
    super.connect()
    this.legacyFunction()
  }

  legacyFunction () {
    var params = {
      "language": {
        "url": "/configs/datatables-russian.json"
      },

      "bPaginate": false,
      "iDisplayLength": -1,
      "bLengthChange": false,
      "processing": false,
      "serverSide": false,
      "autoWidth": true,
    };

    $('#gw-datatable-1').dataTable(params).rowGrouping(
      { bExpandableGrouping: true,
        iGroupingColumnIndex: 0
      });

    $('#gw-datatable-2').dataTable(params).rowGrouping(
      { bExpandableGrouping: true,
        iGroupingColumnIndex: 0
      });
  }

}
